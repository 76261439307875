<template>
  <div class="logistics-address-list-pages">
    <div class="log-list" v-if="list && list.length">
      <a-card
          v-for="(item, index) in list"
          :key="index"
          class="w-45-w log-item"
      >
<!--    标题可操作区域    -->
        <div slot="title" class="">
          <div class="">
            <span :class="orderTypeClassType[item.orderType]">{{ orderTypeList[item.orderType] }}：</span>
            【<span class="cur-pot color-blue" @click="handleGoToOrderListOrReturnOrderList(item)">{{ item.orderNo }}</span>】
          </div>
        </div>
<!--    按钮    -->
        <div slot="extra">
          <a-button type="primary" @click="handleShowLogDetail(item)">查看订单全部发货记录</a-button>
        </div>
<!--    物流单号    -->
        <div class="flex-sp-bt-center">
          <div class="flex-start-center" v-if="item.expressNo">
            <p><span class="color-black font-weight-bold font-size-16">{{ expressCompanyCodeMapText[item.logisticsName] || item.logisticsName }}</span></p>
            <p><span class="color-green font-weight-bold font-size-16 ml-20">{{ item.expressNo }}</span></p>
          </div>
          <div v-else></div>
          <p>发货方式：<span :class="sendProductPayTypeClassType[item.payType] || 'color-red'" class="font-weight-bold font-size-16">{{ sendProductPayType[item.payType] || '线下-手动发货' }}</span></p>
        </div>
<!--    发货人信息    -->
        <a-card>
          <span slot="title" class="color-gray font-size-14">发货人信息</span>
<!--    取消发货相关操作      -->
          <div slot="extra" v-if="item.isCancelDeliver">
            <a-checkbox
                v-if="!item.isShowRefundExpressCost"
                :checked='checkedDelUserConfirmTakeOverProductCountTimeMapOrderNoListStatus[item.expressNo]'
                @change="handleChangeCurrentCheckBoxStatus($event, item.expressNo)"
            >
              是否撤销买家收货倒计时
            </a-checkbox>
            <a-popconfirm
                v-if="!item.isShowRefundExpressCost"
                title="确定取消当前订单发货?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleCancelSendProduct(item)"
            >
              <a-button type="danger" size="small">取消发货</a-button>
            </a-popconfirm>
            <a-button v-else type="danger" size="small" @click="handleShowCancelSendProduct(item)">取消发货</a-button>
          </div>
          <p>发货人姓名：<span class="color-black">{{ item.deliverAddress.userName }}</span></p>
          <p>发货人手机号码：<span class="color-black">{{ item.deliverAddress.telNumber }}</span></p>
          <p>发货人详情地址：<span class="color-black">{{ item.deliverAddress.detailInfo }}</span></p>
        </a-card>
<!--    收货人信息    -->
        <a-card >
          <span slot="title" class="color-gray font-size-14">收货人信息</span>
          <p>收货人姓名：<span class="color-black">{{ item.receiveAddress.userName }}</span></p>
          <p>收货人手机号码：<span class="color-black">{{ item.receiveAddress.telNumber }}</span></p>
          <p>收货人详情地址：<span class="color-black">{{ item.receiveAddress.detailInfo }}</span></p>
        </a-card>
      </a-card>
    </div>
    <a-empty v-else class="null" :description="false" />
<!--  弹窗  -->
    <LogisticsDetailListByOrderNoPopup ref="logisticsDetailListByOrderNoPopupEl"/>
<!--  取消发货弹窗  -->
    <EditorCancelSendProductPopup ref="editorCancelSendProductPopupEl" @editor="handleEditorSuccess"/>
  </div>
</template>
<script>
import {
  ORDER_TYPE,
  ORDER_TYPE_CLASS_TYPE,
  SEND_PRODUCT_PAY_TYPE, SEND_PRODUCT_PAY_TYPE_CLASS_TYPE
} from "@/views/cmsPage/shopManage/senderLogisticsList/_data"
import {goToOtherPage} from "@/untils/otherEvent"
import LogisticsDetailListByOrderNoPopup
  from "@/views/cmsPage/shopManage/senderLogisticsList/_components/LogisticsDetailListByOrderNoPopup/index.vue"
import {EXPRESS_COMPANY_CODE_MAP_TEXT} from "@/_data"
import EditorCancelSendProductPopup
  from "@/views/cmsPage/shopManage/senderLogisticsList/_components/EditorCancelSendProductPopup/index.vue"
import {cancelUserSendProductApi} from "@/views/cmsPage/shopManage/senderLogisticsList/_apis"

export default {
  components: {LogisticsDetailListByOrderNoPopup, EditorCancelSendProductPopup},
  props: ['list'],
  data() {
    return {
      orderTypeList: ORDER_TYPE,
      orderTypeClassType: ORDER_TYPE_CLASS_TYPE,
      sendProductPayType: SEND_PRODUCT_PAY_TYPE,
      sendProductPayTypeClassType: SEND_PRODUCT_PAY_TYPE_CLASS_TYPE,
      expressCompanyCodeMapText: EXPRESS_COMPANY_CODE_MAP_TEXT,
      checkedDelUserConfirmTakeOverProductCountTimeMapOrderNoListStatus: {}
    }
  },
  watch: {
    list() {
      const tempObj = {}
      this.list.forEach(el => {
        tempObj[el.expressNo] = false
      })
      this.checkedDelUserConfirmTakeOverProductCountTimeMapOrderNoListStatus = tempObj
    }
  },
  methods: {
    /** 改变是否需要撤销倒计时 */
    handleChangeCurrentCheckBoxStatus(e, expressNo) {
      this.list.forEach(el => {
        if (el.expressNo === expressNo) {
          this.checkedDelUserConfirmTakeOverProductCountTimeMapOrderNoListStatus[expressNo] = e.target.checked
        } else {
          this.checkedDelUserConfirmTakeOverProductCountTimeMapOrderNoListStatus[el.expressNo] = false
        }
      })
      this.$forceUpdate()
    },
    /** 编辑成功 */
    handleEditorSuccess() {
      this.$emit('editor')
    },
    /** 确定取消发货 */
    async handleShowCancelSendProduct(item) {
      this.$refs.editorCancelSendProductPopupEl.show({
        orderId: item.orderId,
        orderType: item.orderType,
        isShowRefundExpressCost: item.isShowRefundExpressCost
      })
    },
    /** 确定取消发货 */
    async handleCancelSendProduct(item) {
      this.$loading.show()
      const res = await cancelUserSendProductApi({
        orderId: item.orderId,
        orderType: item.orderType,
        isRefundExpressCost: true,
        isRevoke: this.checkedDelUserConfirmTakeOverProductCountTimeMapOrderNoListStatus[item.expressNo],
        isShowRefundExpressCost: item.isShowRefundExpressCost
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success(res.message || '取消成功')
      this.checkedDelUserConfirmTakeOverProductCountTimeMapOrderNoListStatus[item.expressNo] = false
      this.$emit('editor')
    },
    /** 查看物流详情列表 */
    handleShowLogDetail(item) {
      this.$refs.logisticsDetailListByOrderNoPopupEl.show(item)
    },
    /** 去往订单货退款订单列表 */
    handleGoToOrderListOrReturnOrderList(item) {
      if (item.orderType === 3) {
        const url = `/cms/dqplatform/orderreturnmannage?orderNo=${item.orderNo}`
        goToOtherPage(url)
      } else {
        const url = `/cms/dqplatform/ordermannage?orderNo=${item.orderNo}`
        goToOtherPage(url)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.log-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .log-item {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.logistics-address-list-pages {
  margin-top: 20px;
  width: 100%;
}
.null {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>