var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logistics-address-list-pages" },
    [
      _vm.list && _vm.list.length
        ? _c(
            "div",
            { staticClass: "log-list" },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "a-card",
                { key: index, staticClass: "w-45-w log-item" },
                [
                  _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                    _c("div", {}, [
                      _c(
                        "span",
                        { class: _vm.orderTypeClassType[item.orderType] },
                        [
                          _vm._v(
                            _vm._s(_vm.orderTypeList[item.orderType]) + "："
                          )
                        ]
                      ),
                      _vm._v(" 【"),
                      _c(
                        "span",
                        {
                          staticClass: "cur-pot color-blue",
                          on: {
                            click: function($event) {
                              return _vm.handleGoToOrderListOrReturnOrderList(
                                item
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.orderNo))]
                      ),
                      _vm._v("】 ")
                    ])
                  ]),
                  _c(
                    "div",
                    { attrs: { slot: "extra" }, slot: "extra" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleShowLogDetail(item)
                            }
                          }
                        },
                        [_vm._v("查看订单全部发货记录")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex-sp-bt-center" }, [
                    item.expressNo
                      ? _c("div", { staticClass: "flex-start-center" }, [
                          _c("p", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "color-black font-weight-bold font-size-16"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.expressCompanyCodeMapText[
                                      item.logisticsName
                                    ] || item.logisticsName
                                  )
                                )
                              ]
                            )
                          ]),
                          _c("p", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "color-green font-weight-bold font-size-16 ml-20"
                              },
                              [_vm._v(_vm._s(item.expressNo))]
                            )
                          ])
                        ])
                      : _c("div"),
                    _c("p", [
                      _vm._v("发货方式："),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold font-size-16",
                          class:
                            _vm.sendProductPayTypeClassType[item.payType] ||
                            "color-red"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.sendProductPayType[item.payType] ||
                                "线下-手动发货"
                            )
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("a-card", [
                    _c(
                      "span",
                      {
                        staticClass: "color-gray font-size-14",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [_vm._v("发货人信息")]
                    ),
                    item.isCancelDeliver
                      ? _c(
                          "div",
                          { attrs: { slot: "extra" }, slot: "extra" },
                          [
                            !item.isShowRefundExpressCost
                              ? _c(
                                  "a-checkbox",
                                  {
                                    attrs: {
                                      checked:
                                        _vm
                                          .checkedDelUserConfirmTakeOverProductCountTimeMapOrderNoListStatus[
                                          item.expressNo
                                        ]
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleChangeCurrentCheckBoxStatus(
                                          $event,
                                          item.expressNo
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 是否撤销买家收货倒计时 ")]
                                )
                              : _vm._e(),
                            !item.isShowRefundExpressCost
                              ? _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: "确定取消当前订单发货?",
                                      "ok-text": "Yes",
                                      "cancel-text": "No"
                                    },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.handleCancelSendProduct(item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: { type: "danger", size: "small" }
                                      },
                                      [_vm._v("取消发货")]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "a-button",
                                  {
                                    attrs: { type: "danger", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleShowCancelSendProduct(
                                          item
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("取消发货")]
                                )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("p", [
                      _vm._v("发货人姓名："),
                      _c("span", { staticClass: "color-black" }, [
                        _vm._v(_vm._s(item.deliverAddress.userName))
                      ])
                    ]),
                    _c("p", [
                      _vm._v("发货人手机号码："),
                      _c("span", { staticClass: "color-black" }, [
                        _vm._v(_vm._s(item.deliverAddress.telNumber))
                      ])
                    ]),
                    _c("p", [
                      _vm._v("发货人详情地址："),
                      _c("span", { staticClass: "color-black" }, [
                        _vm._v(_vm._s(item.deliverAddress.detailInfo))
                      ])
                    ])
                  ]),
                  _c("a-card", [
                    _c(
                      "span",
                      {
                        staticClass: "color-gray font-size-14",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [_vm._v("收货人信息")]
                    ),
                    _c("p", [
                      _vm._v("收货人姓名："),
                      _c("span", { staticClass: "color-black" }, [
                        _vm._v(_vm._s(item.receiveAddress.userName))
                      ])
                    ]),
                    _c("p", [
                      _vm._v("收货人手机号码："),
                      _c("span", { staticClass: "color-black" }, [
                        _vm._v(_vm._s(item.receiveAddress.telNumber))
                      ])
                    ]),
                    _c("p", [
                      _vm._v("收货人详情地址："),
                      _c("span", { staticClass: "color-black" }, [
                        _vm._v(_vm._s(item.receiveAddress.detailInfo))
                      ])
                    ])
                  ])
                ],
                1
              )
            }),
            1
          )
        : _c("a-empty", { staticClass: "null", attrs: { description: false } }),
      _c("LogisticsDetailListByOrderNoPopup", {
        ref: "logisticsDetailListByOrderNoPopupEl"
      }),
      _c("EditorCancelSendProductPopup", {
        ref: "editorCancelSendProductPopupEl",
        on: { editor: _vm.handleEditorSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }